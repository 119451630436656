import { graphql } from "gatsby";
import React from "react";
import Page from "../../components/page";
import ProtectedRoute from "../../containers/user/components/ProtectedRoute";
import MyPods from "../../containers/user/containers/MyPods";

const UserPodsPage = ({ data }: { data: Queries.MyPodsQueryDataQuery }) => {
  return (
    <>
      <Page {...data.datoCmsWebPage} container="fluid">
        <ProtectedRoute component={MyPods} />
      </Page>
    </>
  );
};

export const query = graphql`
  query MyPodsQueryData($locale: String!) {
    datoCmsWebPage(path: { eq: "user/pods" }, locale: { eq: $locale }) {
      ...WebPageFragment
    }
  }
`;

export default UserPodsPage;
