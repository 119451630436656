import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import LocalizedLink from "../../../../components/localized-link";

type Props = {
  children: JSX.Element | JSX.Element[] | string;
  button?: {
    label: string;
    to: string;
  };
};

export default function EmptyMessage({ children, button }: Props) {
  return (
    <Box
      width="100%"
      bgcolor="grey.100"
      padding={{ xs: 3, md: 4 }}
      borderRadius="8px"
    >
      <Typography variant="body2" align="center">
        {children}
      </Typography>

      {button && (
        <Box mt={4}>
          <Button
            variant="contained"
            component={LocalizedLink}
            to={button.to}
            fullWidth
            color="primary"
          >
            {button.label}
          </Button>
        </Box>
      )}
    </Box>
  );
}
