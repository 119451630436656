import React from "react";
import useProductQuery from "../../../../hooks/use-product-query";
import ProductCard from "../../../shop/ProductCard";
import { ProductListItemType } from "../../../../types/airgraft";
import { PodHistoryType } from "../../../../hooks/use-activated-pod-history";

type Props = {
  podHistory: PodHistoryType;
};

export default function MyPodProductCard({ podHistory }: Props) {
  const { data: product, isLoading, isError } = useProductQuery({
    batchId: podHistory.batchId
  });

  if (isError || (!product && !product)) {
    return null;
  }

  return (
    <ProductCard
      product={product as ProductListItemType}
      variant={isLoading ? "SKELETON" : "MY_PODS"}
      podHistory={podHistory}
    />
  );
}
