import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  styled,
  Typography,
  useTheme
} from "@material-ui/core";
import { ChevronRight } from "@material-ui/icons";
import groupBy from "lodash/groupBy";
import moment from "moment-timezone";
import React from "react";
import { useIsFetching } from "react-query";
import { BooleanParam, StringParam, useQueryParam } from "use-query-params";
import BackButton from "../../../../components/back-navigation/BackButton";
import LocalizedLink from "../../../../components/localized-link";
import { List as ProductsListWrapper } from "../../../../containers/shop/ProductsList";
import { SHOP_PRODUCTS_PATH } from "../../../../helpers/url";
import useActivatedPodHistory from "../../../../hooks/use-activated-pod-history";
import useTranslate from "../../../../hooks/use-translate";
import EmptyMessage from "../../components/EmptyMessage";
import UserAccountLayout from "../../components/UserAccountLayout";
import MyPodProductCard from "./MyPodProductCard";
import RatingIconSvg from "./rating.svg";

const WideContainer = ({ children }) => (
  <Container maxWidth="xl">{children}</Container>
);

const UnratedBanner = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.05)",
  backgroundColor: theme.palette.grey[100],
  padding: theme.spacing(1.5, 2),
  borderRadius: "8px",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.grey[50]
  }
}));

export default function MyPods() {
  const t = useTranslate();
  const theme = useTheme();

  const [filterOnlyUnrated, setUnratedFilter] = useQueryParam(
    "unrated",
    BooleanParam
  );
  const [filterYear, setYearFilter] = useQueryParam("year", StringParam);

  const { data: podHistory, isLoading: isLoadingPodHistory } =
    useActivatedPodHistory();
  const hasPods = podHistory?.content?.length > 0;

  const isFetchingProductDetails = useIsFetching("products-details") > 0;

  // Filter unrated pods
  const unratedPods =
    podHistory?.content?.filter(p => !p.userRating?.rating) || [];
  const hasUnratedPods = unratedPods.length > 0;

  const allPods = podHistory?.content;
  let pods = filterOnlyUnrated ? unratedPods : allPods;

  // Filter pods by year
  if (filterYear) {
    pods = pods.filter(
      pod => moment(pod.createdDate).format("YYYY") === filterYear
    );
  }

  // Group pods by month and year
  const podsGroupedByMonths = groupBy(pods, pod => {
    return moment(pod.createdDate).format("MM-YYYY");
  });
  const years = (allPods || []).reduce<string[]>((accumulator, podHistory) => {
    const year = moment(podHistory.createdDate).format("YYYY");
    return accumulator.includes(year) ? accumulator : [...accumulator, year];
  }, []);

  // UI visibility flags
  const showUnratedPodsBanner = hasUnratedPods && !filterOnlyUnrated;
  // const showYearsFilter = years.length > 1 && pods.length > 0;
  const showYearsFilter = pods && pods.length > 0 && !filterOnlyUnrated;
  const showListGroupedByMonths = !filterOnlyUnrated;

  const yearFilterForm = (
    <FormControl variant="outlined" fullWidth>
      <InputLabel id="filter-year">{t("myPods.filterYear")}</InputLabel>
      <Select
        id="year"
        labelId="filter-year"
        variant="outlined"
        fullWidth
        value={filterYear || null}
        onChange={event => setYearFilter(event.target.value as string)}
      >
        <MenuItem key="all" value={null}>
          {t("myPods.allYears")}
        </MenuItem>
        {years.map(year => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  return (
    <UserAccountLayout
      showLoading={isLoadingPodHistory || isFetchingProductDetails}
      customContainer={hasPods ? WideContainer : undefined}
    >
      {filterOnlyUnrated && (
        <BackButton onClick={() => setUnratedFilter(undefined)}>
          {t("back")}
        </BackButton>
      )}

      {/* Header: Title + "Add" button + Desktop Year filter */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        marginBottom={4}
      >
        <Typography variant="h4">
          {t(filterOnlyUnrated ? "myPods.title.unrated" : "myPods.title")}
        </Typography>

        <Box display="flex" alignContent="center">
          {!filterOnlyUnrated && (
            <Box display="flex" alignItems="center">
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: "100px", fontSize: "1.2rem" }}
                component={LocalizedLink}
                to="/unlock/enter-code/"
              >
                {t("myPods.add")}
              </Button>
            </Box>
          )}

          {showYearsFilter && (
            <Box
              marginLeft={3}
              display={{ xs: "none", md: "flex" }}
              minWidth="140px"
            >
              {yearFilterForm}
            </Box>
          )}
        </Box>
      </Box>

      {/* Unrated pods banner */}
      {showUnratedPodsBanner && (
        <Box marginBottom={{ xs: 2, md: 4 }}>
          <UnratedBanner onClick={() => setUnratedFilter(true)}>
            <Box display="flex" alignItems="center">
              <Box mr={1}>
                <img src={RatingIconSvg} width={24} height={24} />
              </Box>
              <Typography variant="body2">{t("myPods.unrated")}</Typography>
            </Box>
            <ChevronRight />
          </UnratedBanner>
        </Box>
      )}

      {/* Mobile Year Filter */}
      {showYearsFilter && (
        <Box
          marginBottom={{ xs: 2, md: 4 }}
          display={{ xs: "block", md: "none" }}
        >
          {yearFilterForm}
        </Box>
      )}

      {/* No unlocked pods */}
      {!hasPods && (
        <EmptyMessage
          button={{
            label: t("shop.browseAllProducts"),
            to: SHOP_PRODUCTS_PATH
          }}
        >
          {t(`myPods.noPods`)}
        </EmptyMessage>
      )}

      {/* Unlocked pods history */}
      {hasPods && !showListGroupedByMonths && (
        <ProductsListWrapper key={filterOnlyUnrated ? "unrated" : "all"}>
          {pods.map(podHistory => (
            <MyPodProductCard
              key={podHistory.batchId}
              podHistory={podHistory}
            />
          ))}
        </ProductsListWrapper>
      )}

      {hasPods && showListGroupedByMonths && (
        <>
          {Object.entries(podsGroupedByMonths).map(([monthYear, pods]) => (
            <Box
              key={monthYear}
              marginBottom={{ xs: 2 }}
              display="flex"
              flexDirection="column"
              alignItems="stretch"
            >
              <Box
                borderBottom={`1px solid ${theme.palette.grey[500]}`}
                paddingBottom={{ xs: 0.75, md: 1 }}
                marginBottom={{ xs: 2, md: 3 }}
              >
                <Typography
                  variant="body2"
                  className="compact"
                  style={{ color: theme.palette.grey[500] }}
                >
                  {moment(monthYear, "MM-YYYY").format("MMMM YYYY")}
                </Typography>
              </Box>

              <ProductsListWrapper key={filterOnlyUnrated ? "unrated" : "all"}>
                {pods.map(podHistory => (
                  <MyPodProductCard
                    key={podHistory.batchId}
                    podHistory={podHistory}
                  />
                ))}
              </ProductsListWrapper>
            </Box>
          ))}
        </>
      )}
    </UserAccountLayout>
  );
}
